/* ------------------ General ------------------ */

#a4div {
  height: 1in;
  left: -100%; 
  position: absolute; 
  top: -100%; 
  width: 1in;
}

#account_photo {
  margin-left: 20px;
  border-radius: 50%;
}

/* ------------------ Clearbit ------------------ */
#autocomplete {
  position: relative;
  z-index: 99999999;
}
#autocomplete input {
  width: 250px;
  background-color: #f9fafb;
  border: 1px solid #e6e9ec;
  box-sizing: border-box;
  height: 50px;
  font-size: 15px;
  padding: 0 15px;
  outline: none;
  z-index: 99999999;
}
#autocomplete .companies {
  max-width: 800px;
  min-width: 500px;
  position: absolute;
  background-color: #fff;
  border-color: #ccc;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
#autocomplete .companies .company {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 5px;
}
#autocomplete .companies .company.selected {
  background: #f0f0f0;
}
#autocomplete .companies .company img {
  width: 30px;
}
#autocomplete .companies .company .company-name {
  margin-left: 7px;
}
#autocomplete .companies .company .company-domain {
  margin-left: auto;
}

/* ------------------ Canvas ------------------ */